import React from "react";
import "./footerBanner.css";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

const FooterBanner = () => {
  return (
    <div className="footerBanner">
      <MDBFooter
        bgColor="dark"
        className="text-center text-lg-start text-muted"
      >
        <section className="d-flex justify-content-center justify-content-lg-between p-2"></section>

        <section className="" style={{ color: "#ffffff" }}>
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <MDBIcon color="secondary" icon={"gem"} />
                  EVN auto transport
                </h6>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Vitae molestiae perspiciatis suscipit omnis sequi perferendis
                  aut quas ipsam illo excepturi voluptatem ea, voluptate
                  accusamus autem explicabo error eveniet ducimus aliquam.
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <MDBIcon color="secondary" />
                  EVN Auto Transport
                  <br />
                  154 County Rd
                  <br />
                  Southampton, MA 01073
                </p>
                <p>
                  <MDBIcon color="secondary" />
                  evnautotransport@gmail.com
                </p>
                <p>
                  <MDBIcon color="secondary" /> + 508-635-1561
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
};

export default FooterBanner;
