import * as yup from "yup";

export const orderSchema = yup.object().shape({
  datepickerOrder: yup.string().required("Required"),
  carrierTypeOrder: yup.string(),
  firstNameOrder: yup.string().required("Required"),
  lastNameOrder: yup.string().required("Required"),
  phoneOrder: yup.string().required("Required"),
  locationTypeOrder: yup.string(),
  contactPersonOrder: yup.string().required("Required"),
  pickupAdressOrder: yup.string().required("Required"),
  emailOrder: yup
    .string()
    .email("Please enter a valid email")
    .required("Required"),
  pickupLocationOrder: yup.string().required("Required"),
  primaryPhoneOrder: yup.string().required("Required"),
  secondaryPhoneOrder: yup.string().required("Required"),
  locationTypeOrder1: yup.string(),
  contactPersonOrder1: yup.string().required("Required"),
  pickupAdressOrder1: yup.string().required("Required"),
  pickupLocationOrder1: yup.string().required("Required"),
  primaryPhoneOrder1: yup.string().required("Required"),
  secondaryPhoneOrder1: yup.string().required("Required"),
  vehicleOrder: yup.string(),
  conditionOrder: yup.string().required("Required"),
});
