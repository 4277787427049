import React from 'react';
import './miniCityBanner.css';
import TexasPh from '../../../../assets/miniCityBanner/texas.jpg'
import FloridaPh from '../../../../assets/miniCityBanner/florida.jpg'
import CaliforniaPh from '../../../../assets/miniCityBanner/california.jpg'
import NewYorkPh from '../../../../assets/miniCityBanner/newYork.jpg'
import { Container, Row, Col, Card } from 'react-bootstrap';


function MiniCityBanner() {
    return (
        <div className="miniCityBanner" id='miniCityBanner'>
            <Container>
                <Row className='cityRow'>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src={TexasPh} />
                            <Card.Body>
                                <Card.Title>Texas</Card.Title>
                                <Card.Text>
                                    Car shipping services anywhere to or from The Golden State
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card >
                            <Card.Img variant="top" src={FloridaPh} />
                            <Card.Body>
                                <Card.Title>Florida</Card.Title>
                                <Card.Text>
                                    Florida auto transport services, to and from The Sunshine State
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card >
                            <Card.Img variant="top" src={CaliforniaPh} />
                            <Card.Body>
                                <Card.Title>California</Card.Title>
                                <Card.Text>
                                    Vehicle shipping services to and throughout The Lone Star State
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card >
                            <Card.Img variant="top" src={NewYorkPh} />
                            <Card.Body>
                                <Card.Title>New York</Card.Title>
                                <Card.Text>
                                    Transport your car anywhere to or from The Empire State
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    );
}

export default MiniCityBanner;