import React from "react";
import "./menu.css";
import { useState } from "react";
import {
  Container,
  NavDropdown,
  Nav,
  Offcanvas,
  Button,
} from "react-bootstrap";
import MenuIcon from "../../../assets/menu/menu";
import Fixed from "../../fixed/fixed";
import { constants } from "../../../constants/api";

const Menu = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional smooth scrolling behavior
    });
  };

  return (
    <div className="menu">
      <div className="menuItem1Vertical">
        <div className="vertical">
          <div className="verticalText">
            <h7>
              <a style={{ all: "unset" }} href={"tel:" + constants.mobPhone}>
                508-635-1561
              </a>
            </h7>
          </div>
          <div className="vertical1" onClick={handleScrollToTop}>
            <h7>QUOTE</h7>
          </div>
          <div>
            <Button
              className="vertical2"
              onClick={handleShow}
              style={{
                backgroundColor: "GrayText",
                borderColor: "GrayText",
              }}
            >
              <MenuIcon />
            </Button>
          </div>
        </div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Container className="containerElem flex-column">
              <NavDropdown title="HOW IT WORKS" id="basic-nav-dropdown">
                <NavDropdown.Item href="#describe" onClick={handleClose}>
                  Describe
                </NavDropdown.Item>
                <NavDropdown.Item href="#describeStep" onClick={handleClose}>
                  Steps
                </NavDropdown.Item>
              </NavDropdown>
            </Container>
            <p1>_________________________</p1>
            <Container className="containerElem">
              <NavDropdown title="OUR SERVICES" id="basic-nav-dropdown">
                <NavDropdown.Item href="#miniTypeBanner" onClick={handleClose}>
                  Services
                </NavDropdown.Item>
                <NavDropdown.Item href="#miniCityBanner" onClick={handleClose}>
                  City Delivery
                </NavDropdown.Item>
              </NavDropdown>
            </Container>
            <p1>_________________________</p1>
            <Container className="containerElem">
              <Nav.Link href="#gallary" onClick={handleClose}>
                GALLERY
              </Nav.Link>
            </Container>
            <p1>_________________________</p1>
            <Container className="containerElem">
              <Nav.Link href="#faq" onClick={handleClose}>
                FAQ
              </Nav.Link>
            </Container>
            <p1>_________________________</p1>
            <Container className="containerElem">
              <Nav.Link href="#footer" onClick={handleClose}>
                CONTACTS
              </Nav.Link>
            </Container>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className="menuItem1">
        <Container className="containerElem">
          <NavDropdown title="HOW IT WORKS" id="basic-nav-dropdown">
            <NavDropdown.Item href="#describe">Describe</NavDropdown.Item>
            <NavDropdown.Item href="#describeStep">Steps</NavDropdown.Item>
          </NavDropdown>
        </Container>
        <p1>|</p1>
        <Container className="containerElem">
          <NavDropdown title="OUR SERVICES" id="basic-nav-dropdown">
            <NavDropdown.Item href="#miniTypeBanner">Services</NavDropdown.Item>
            <NavDropdown.Item href="#miniCityBanner">
              City Delivery
            </NavDropdown.Item>
          </NavDropdown>
        </Container>
        <p1>|</p1>
        <Container className="containerElem">
          <Nav.Link href="#gallary">GALLERY</Nav.Link>
        </Container>
        <p1>|</p1>
        <Container className="containerElem">
          <Nav.Link href="#faq">FAQ</Nav.Link>
        </Container>
        <p1>|</p1>
        <Container className="containerElem">
          <Nav.Link href="#footer">CONTACTS</Nav.Link>
        </Container>
      </div>
      <div className="menuItem3">
        <Button>
          <h7>QUOTE</h7>
        </Button>
      </div>
      <div className="menuItem2">
        <h7>508-635-1561</h7>
      </div>
    </div>
  );
};

export default Menu;
