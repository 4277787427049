import * as yup from "yup";

export const commonSchema = yup.object().shape({
  orderTransportFrom: yup
    .string()
    .min(5, "The field must contain at least 5 characters")
    .required("Required"),
  orderTransportTo: yup
    .string()
    .min(5, "The field must contain at least 5 characters")
    .required("Required"),
});
