import * as yup from "yup";

export const quoteSchema = yup.object().shape({
  pickup: yup
    .string()
    .min(5, "The field must contain at least 5 characters")
    .required("Required"),
  delivery: yup
    .string()
    .min(5)
    .min(5, "The field must contain at least 5 characters")
    .required("Required"),
  year: yup.string().required("Required"),
  make: yup.string().required("Required"),
  model: yup.string().required("Required"),
  datepicker: yup.string().required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Please enter a valid email").required("Required"),
  phone: yup.string().required("Required"),
});
