import React from "react";
import "./faq.css";
import { Accordion } from "react-bootstrap";

function FAQ() {
  return (
    <div className="faq pt-5 pb-5" id="faq">
      <div>
        <h1>FAQ</h1>
      </div>

      <div className="accordion">
        <h4>EVN Offerings</h4>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Why EVN Auto Transport?</Accordion.Header>
            <Accordion.Body>
              We are an auto transporter committed to providing a superior
              experience for our customers. We offer top-notch customer service
              with thousands of five-star reviews to prove it. Our honest,
              transparent pricing is backed by our exclusive Price Lock Promise.
              Simply put, the price you’re quoted is the price you pay. These
              efforts coupled with our thorough carrier vetting process ensure a
              successful transport for our customers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              What is the Clean Car Guarantee?
            </Accordion.Header>
            <Accordion.Body>
              After hearing from some of our customers that their vehicle
              arrived at its destination dusty – we’re proud to offer our Clean
              Car Guarantee to address this issue. Once your vehicle arrives at
              its final destination, treat yourself to a car wash – on us! Take
              your car to get washed and we’ll reimburse you up to $20 for the
              car wash.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What type of services do you offer?
            </Accordion.Header>
            <Accordion.Body>
              EVN is proud to provide top-notch auto transport across the 48
              contiguous US states and Hawaii. No matter your reason for
              shipping your vehicle, we are here for you. We move sedans, SUVs,
              pickup trucks and vans – everything from rare, collectible autos
              to family cars to inoperable vehicles with the ultimate care. We
              offer convenient door-to-door transport and can accommodate
              expedited pickup windows for those in a hurry.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <h4>Before You Ship</h4>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>How do I receive a quote?</Accordion.Header>
            <Accordion.Body>
              Let’s get started! Many of our customers can receive an instant
              quote and book their shipment fully online. Our auto transport
              experts are here to help answer any questions to set up your
              shipment for success. Give us a call at 508-635-1561
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How far out should I schedule my auto shipment?
            </Accordion.Header>
            <Accordion.Body>
              We recommend scheduling your auto shipment as soon as possible to
              find a carrier for your preferred pickup window at the lowest
              price. We also offer expedited pickup options for our customers in
              a bind. To discuss the best option to fit your needs, please call
              us at 508-635-1561
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Can I choose my pickup and delivery days?
            </Accordion.Header>
            <Accordion.Body>
              While we cannot guarantee a pickup or delivery date, we recommend
              booking with as much advance notice as possible if you have
              preferences related to your transport. Our standard estimated
              pickup window is 5 business days. For those in a hurry, we offer
              expedited pickup windows for an additional fee. Give our team a
              call at 508-635-1561 to discuss the best option for your shipment.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What if my vehicle isn’t roadworthy?
            </Accordion.Header>
            <Accordion.Body>
              We can move inoperable vehicles. There is an additional fee for
              this service, but as long as the vehicle can roll, brake, and
              steer, our carriers can load it onto their standard trailers. The
              carrier will also need a key to the vehicle in order to transport
              it.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default FAQ;
