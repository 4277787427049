import React from "react";
import "./bannerOrder.css";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { orderSchema } from "./bannerOrderSchema";
import axios from "axios";
import { constants } from "../../../constants/api";

const BannerOrder = ({ showOrder, setShowOrder }) => {
  const handleClose = () => setShowOrder(false);

  const onSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
    await axios({
      method: "post",
      url: `${constants.url}/order`,
      data: values,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        console.log(values);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    new Promise((resolve) => setTimeout(resolve, 1000));
    handleClose();
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: {
      datepickerOrder: "",
      carrierTypeOrder: "",
      firstNameOrder: "",
      lastNameOrder: "",
      emailOrder: "",
      phoneOrder: "",
      locationTypeOrder: "",
      contactPersonOrder: "",
      pickupAdressOrder: "",
      pickupLocationOrder: "",
      primaryPhoneOrder: "",
      secondaryPhoneOrder: "",
      locationTypeOrder1: "",
      contactPersonOrder1: "",
      pickupAdressOrder1: "",
      pickupLocationOrder1: "",
      primaryPhoneOrder1: "",
      secondaryPhoneOrder1: "",
      vehicleOrder: "",
      conditionOrder: "",
    },
    validationSchema: orderSchema,
    onSubmit,
  });

  console.log(errors);

  return (
    <>
      <Modal
        show={showOrder}
        onHide={handleClose}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>ORDER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            class=" orderForm d-flex flex-column  needs-validation"
            method="POST"
            id="orderForm"
            style={{ width: "100%" }}
            onSubmit={handleSubmit}
          >
            <div class="shipD">
              <h3>Shipment Details</h3>

              <div class="container text-left">
                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      class="form-control"
                      id="datepickerOrder"
                      name="datepickerOrder"
                      value={values.datepickerOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.datepickerOrder && touched.datepickerOrder
                          ? "control-error"
                          : ""
                      }
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Carrier type
                    </Form.Label>
                    <div class="d-flex svgF">
                      <select
                        class="form-select"
                        id="carrierTypeOrder"
                        name="carrierTypeOrder"
                        value={values.carrierTypeOrder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Open/Standart</option>
                        <option value="">Open/Top Load</option>
                        <option value="">Enclosed</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="primaryBookingD">
              <h3>Primary Booking Contact</h3>

              <div class="container text-left">
                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="firstNameOrder"
                      name="firstNameOrder"
                      placeholder="Enter your first name"
                      value={values.firstNameOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.firstNameOrder && touched.firstNameOrder
                          ? "control-error"
                          : ""
                      }
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="lastNameOrder"
                      name="lastNameOrder"
                      value={values.lastNameOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.lastNameOrder && touched.lastNameOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter your last name"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="emailOrder"
                      name="emailOrder"
                      value={values.emailOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.emailOrder && touched.emailOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter your email"
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="phoneOrder"
                      name="phoneOrder"
                      value={values.phoneOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.phoneOrder && touched.phoneOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter your phone number"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="pickupL">
              <h3>Pickup Location</h3>

              <div class="container text-left">
                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Location Type
                    </Form.Label>
                    <div class="d-flex ">
                      <select
                        class="form-select"
                        id="locationTypeOrder"
                        name="locationTypeOrder"
                        value={values.locationTypeOrder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Residence</option>
                        <option value="">Business</option>
                      </select>
                    </div>
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Contact Person (Full Name)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="contactPersonOrder"
                      name="contactPersonOrder"
                      value={values.contactPersonOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.contactPersonOrder && touched.contactPersonOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter Contact person full name"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Pickup Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="pickupAdressOrder"
                      name="pickupAdressOrder"
                      value={values.pickupAdressOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.pickupAdressOrder && touched.pickupAdressOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter pickup address"
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Pickup Location
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="pickupLocationOrder"
                      name="pickupLocationOrder"
                      value={values.pickupLocationOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.pickupLocationOrder &&
                        touched.pickupLocationOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter pickup location"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Primary Phone
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="primaryPhoneOrder"
                      name="primaryPhoneOrder"
                      value={values.primaryPhoneOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.primaryPhoneOrder && touched.primaryPhoneOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter primary phone"
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Secondary Phone (Optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="secondaryPhoneOrder"
                      name="secondaryPhoneOrder"
                      value={values.secondaryPhoneOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.secondaryPhoneOrder &&
                        touched.secondaryPhoneOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter secondary phone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="deliveryL">
              <h3>Delivery Location</h3>

              <div class="container text-left">
                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Location Type
                    </Form.Label>
                    <div class="d-flex">
                      <select
                        class="form-select"
                        id="locationTypeOrder1"
                        name="locationTypeOrder1"
                        value={values.locationTypeOrder1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Residence</option>
                        <option value="">Business</option>
                      </select>
                    </div>
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Contact Person (Full Name)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="contactPersonOrder1"
                      name="contactPersonOrder1"
                      value={values.contactPersonOrder1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.contactPersonOrder1 &&
                        touched.contactPersonOrder1
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter Contact person full name"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Pickup Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="pickupAdressOrder1"
                      name="pickupAdressOrder1"
                      value={values.pickupAdressOrder1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.pickupAdressOrder1 && touched.pickupAdressOrder1
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter pickup address"
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Pickup Location
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="pickupLocationOrder1"
                      name="pickupLocationOrder1"
                      value={values.pickupLocationOrder1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.pickupLocationOrder1 &&
                        touched.pickupLocationOrder1
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter pickup location"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Primary Phone
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="primaryPhoneOrder1"
                      name="primaryPhoneOrder1"
                      value={values.primaryPhoneOrder1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.primaryPhoneOrder1 && touched.primaryPhoneOrder1
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter primary phone"
                    />
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Secondary Phone (Optional)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="secondaryPhoneOrder1"
                      name="secondaryPhoneOrder1"
                      value={values.secondaryPhoneOrder1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.secondaryPhoneOrder1 &&
                        touched.secondaryPhoneOrder1
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter secondary phone"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="vehicleD">
              <h3>Vehicle Details</h3>

              <div class="container text-left">
                <div class="row">
                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Vehicle
                    </Form.Label>
                    <div class="d-flex">
                      <select
                        class="form-select"
                        id="vehicleOrder"
                        name="vehicleOrder"
                        value={values.vehicleOrder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Runs & Drive</option>
                        <option value="">Inoperable</option>
                      </select>
                    </div>
                  </div>

                  <div class="col">
                    <Form.Label for="phone" class="form-Form.Label">
                      Condition
                    </Form.Label>
                    <Form.Control
                      type="text"
                      class="form-control"
                      id="conditionOrder"
                      name="conditionOrder"
                      value={values.conditionOrder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.conditionOrder && touched.conditionOrder
                          ? "control-error"
                          : ""
                      }
                      placeholder="Enter condition"
                      style={{ color: "#999" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Send
              </Button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default BannerOrder;
