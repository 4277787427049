import React from "react";
import "./bannerQuote.css";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { quoteSchema } from "./bannerQuoteSchema";
import axios from "axios";
import { constants } from "../../../constants/api";

const BannerQuote = ({ showQuote, setShowQuote }) => {
  const handleClose = () => {
    setShowQuote(false);
  };

  const onSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
    await axios({
      method: "post",
      url: `${constants.url}/quote`,
      data: values,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        console.log(values);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    new Promise((resolve) => setTimeout(resolve, 1000));
    handleClose();
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    isSubmitting,
    handleChange,
    isValid,
    isDirty,
    handleSubmit,
  } = useFormik({
    initialValues: {
      pickup: "",
      delivery: "",
      year: "",
      make: "",
      model: "",
      datepicker: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validationSchema: quoteSchema,
    onSubmit,
  });

  console.log(errors);

  return (
    <>
      <Modal
        show={showQuote}
        onHide={handleClose}
        backdrop={"static"}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>QUOTE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            class="row g-2 needs-validation quoteForm"
            method="POST"
            id="quoteForm"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div class="col-md-6">
              <Form.Label for="pickup" class="form-Form.Label">
                Pickup Location
              </Form.Label>
              <Form.Control
                type="text"
                class="form-control targetForm.Control"
                id="pickup"
                placeholder="Enter 5-digit ZIP code"
                value={values.pickup}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.pickup && touched.pickup ? "control-error" : ""
                }
              />
              {errors.pickup && touched.pickup && (
                <p className="">{errors.pickup}</p>
              )}
            </div>
            <div class="col-md-6">
              <Form.Label for="delivery" class="form-Form.Label">
                Delivery Location
              </Form.Label>
              <Form.Control
                type="text"
                class="form-control targetForm.Control"
                id="delivery"
                placeholder="Enter 5-digit ZIP code"
                value={values.delivery}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.delivery && touched.delivery ? "control-error" : ""
                }
              />
              {errors.delivery && touched.delivery && (
                <p className="">{errors.delivery}</p>
              )}
            </div>

            <div class="col-md-12">
              <Form.Label for="year" class="form-Form.Label">
                Year
              </Form.Label>
              <select
                className={
                  errors.year && touched.year
                    ? "form-select control-error"
                    : "form-select"
                }
                id="year"
                value={values.year}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option selected disabled value="">
                  Choose...
                </option>
                <option value="2023">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                <option value="2009">2009</option>
                <option value="2008">2008</option>
                <option value="2007">2007</option>
                <option value="2006">2006</option>
                <option value="2005">2005</option>
                <option value="2004">2004</option>
                <option value="2003">2003</option>
                <option value="2002">2002</option>
                <option value="2001">2001</option>
                <option value="2000">2000</option>
                <option value="1999">1999</option>
                <option value="1998">1998</option>
                <option value="1997">1997</option>
                <option value="1996">1996</option>
                <option value="1995">1995</option>
                <option value="1994">1994</option>
                <option value="1993">1993</option>
                <option value="1992">1992</option>
                <option value="1991">1991</option>
                <option value="1990">1990</option>
                <option value="1989">1989</option>
                <option value="1988">1988</option>
                <option value="1987">1987</option>
                <option value="1986">1986</option>
                <option value="1985">1985</option>
                <option value="1984">1984</option>
                <option value="1983">1983</option>
                <option value="1982">1982</option>
                <option value="1981">1981</option>
                <option value="1980">1980</option>
                <option value="1979">1979</option>
                <option value="1978">1978</option>
                <option value="1977">1977</option>
                <option value="1976">1976</option>
                <option value="1975">1975</option>
                <option value="1974">1974</option>
                <option value="1973">1973</option>
                <option value="1972">1972</option>
                <option value="1971">1971</option>
                <option value="1970">1970</option>
                <option value="1969">1969</option>
                <option value="1968">1968</option>
                <option value="1967">1967</option>
                <option value="1966">1966</option>
                <option value="1965">1965</option>
                <option value="1964">1964</option>
                <option value="1963">1963</option>
                <option value="1962">1962</option>
                <option value="1961">1961</option>
                <option value="1960">1960</option>
                <option value="1959">1959</option>
                <option value="1958">1958</option>
                <option value="1957">1957</option>
                <option value="1956">1956</option>
                <option value="1955">1955</option>
                <option value="1954">1954</option>
                <option value="1953">1953</option>
                <option value="1952">1952</option>
                <option value="1951">1951</option>
                <option value="1950">1950</option>
                <option value="1949">1949</option>
                <option value="1948">1948</option>
                <option value="1947">1947</option>
                <option value="1946">1946</option>
                <option value="1945">1945</option>
                <option value="1944">1944</option>
                <option value="1943">1943</option>
                <option value="1942">1942</option>
                <option value="1941">1941</option>
                <option value="1940">1940</option>
                <option value="1939">1939</option>
                <option value="1938">1938</option>
                <option value="1937">1937</option>
                <option value="1936">1936</option>
                <option value="1935">1935</option>
                <option value="1934">1934</option>
                <option value="1933">1933</option>
                <option value="1932">1932</option>
                <option value="1931">1931</option>
                <option value="1930">1930</option>
              </select>
            </div>
            <div class="col-md-12">
              <Form.Label for="make" class="form-Form.Label">
                Make
              </Form.Label>
              <select
                className={
                  errors.make && touched.make
                    ? "form-select control-error"
                    : "form-select"
                }
                id="make"
                value={values.make}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option selected disabled value="">
                  Choose...
                </option>
                <option value="Acura">Acura</option>
                <option value="Alfa Romeo">Alfa Romeo</option>
                <option value="Aston Martin">Aston Martin</option>
                <option value="Audi">Audi</option>
                <option value="Bentley">Bentley</option>
                <option value="BMW">BMW</option>
                <option value="Buick">Buick</option>
                <option value="Cadillac">Cadillac</option>
                <option value="Chevrolet">Chevrolet</option>
                <option value="Chrysler">Chrysler</option>
                <option value="Dodge">Dodge</option>
                <option value="FIAT">FIAT</option>
                <option value="Ford">Ford</option>
                <option value="Genesis">Genesis</option>
                <option value="GMC">GMC</option>
                <option value="Honda">Honda</option>
                <option value="Hyundai">Hyundai</option>
                <option value="INFINITI">INFINITI</option>
                <option value="Jaguar">Jaguar</option>
                <option value="Jeep">Jeep</option>
                <option value="Karma">Karma</option>
                <option value="Kia">Kia</option>
                <option value="Lamborghini">Lamborghini</option>
                <option value="Land Rover">Land Rover</option>
                <option value="Lexus">Lexus</option>
                <option value="Lincoln">Lincoln</option>
                <option value="Lucid">Lucid</option>
                <option value="Maserati">Maserati</option>
                <option value="Mazda">Mazda</option>
                <option value="McLaren">McLaren</option>
                <option value="Mercedes-Benz">Mercedes-Benz</option>
                <option value="MINI">MINI</option>
                <option value="Mitsubishi">Mitsubishi</option>
                <option value="Nissan">Nissan</option>
                <option value="Polestar">Polestar</option>
                <option value="Porsche">Porsche</option>
                <option value="Ram">Ram</option>
                <option value="Rivian">Rivian</option>
                <option value="Rolls-Royce">Rolls-Royce</option>
                <option value="Subaru">Subaru</option>
                <option value="Tesla">Tesla</option>
                <option value="Toyota">Toyota</option>
                <option value="Volkswagen">Volkswagen</option>
                <option value="Volvo">Volvo</option>
              </select>
            </div>
            <div>
              <Form.Label for="model" class="form-Form.Label">
                Model
              </Form.Label>
              <Form.Control
                type="text"
                class="form-control targetForm.Control"
                id="model"
                placeholder="Write a model"
                value={values.model}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.model && touched.model ? "control-error" : ""}
              />
            </div>

            <div class="col-md-12">
              <Form.Label for="datepicker">Select Shipping Date:</Form.Label>
              <Form.Control
                type="date"
                class="form-control"
                id="datepicker"
                value={values.datepicker}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.datepicker && touched.datepicker ? "control-error" : ""
                }
              />
            </div>

            <div>
              <Form.Label for="firstName" class="form-Form.Label">
                First Name
              </Form.Label>
              <Form.Control
                type="text"
                class="form-control targetForm.Control"
                id="firstName"
                placeholder="Enter your first name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.firstName && touched.firstName ? "control-error" : ""
                }
              />
            </div>

            <div>
              <Form.Label for="lastName" class="form-Form.Label">
                Last Name
              </Form.Label>
              <Form.Control
                type="text"
                class="form-control targetForm.Control"
                id="lastName"
                placeholder="Enter your last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.lastName && touched.lastName ? "control-error" : ""
                }
              />

              <div>
                <Form.Label for="email" class="form-Form.Label">
                  Email
                </Form.Label>
                <Form.Control
                  type="text"
                  class="form-control targetForm.Control"
                  id="email"
                  placeholder="Enter your email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email ? "control-error" : ""
                  }
                />
                {errors.email && touched.email && (
                  <p className="">{errors.email}</p>
                )}
              </div>
              <div>
                <Form.Label for="phone" class="form-Form.Label">
                  Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  class="form-control targetForm.Control"
                  id="phone"
                  placeholder="Enter your phone number"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone && touched.phone ? "control-error" : ""
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Send
              </Button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default BannerQuote;
