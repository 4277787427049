import React from "react";
import "./miniTypeBanner.css";
import { Container, Row, Col, Card } from "react-bootstrap";

function MiniTypeBanner() {
  return (
    <div className="miniTypeBanner" id="miniTypeBanner">
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="text-center">
                  Door-to-door car shipping
                </Card.Title>
                <Card.Text>
                  Door-to-door service is a convenient way to ship your car. You
                  provide specific addresses and the truck driver will pickup
                  and drop off your vehicle as close to your door as safely and
                  legally possible. Keep in mind that some cities have
                  restrictions on large trucks that prohibit them from entering
                  some residential areas.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card id="id_2">
              <Card.Body>
                <Card.Title className="text-center">
                  {" "}
                  Enclosed auto transport{" "}
                </Card.Title>
                <Card.Text>
                  We offer a vip service for our more expensive and priceless
                  vehicles. Ask our sales team for more information!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card id="id_3">
              <Card.Body>
                <Card.Title className="text-center">
                  {" "}
                  Motorcycle shipping{" "}
                </Card.Title>
                <Card.Text>
                  Montway provides our motorcycle transport customers with the
                  best year-round pricing. We use experienced drivers who have
                  the skills and equipment to properly load, secure and
                  transport motorcycles, scooters, ATVs, UTVs and powersports.
                  Call
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MiniTypeBanner;
