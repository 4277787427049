import React from 'react';
import './main.css';
import HowItWorks from '../main/howItWorks/howItWorks';
import OurServices from './ourServices/ourServices';
import FAQ from './faq/faq';
import Gallary from './gallary/gallary';

function Main() {
    return (
        <div className="main">
            <HowItWorks />
            <OurServices />
            <Gallary />
            <FAQ />
        </div>

    );
}

export default Main;