import React from "react";
import "./bannerPresentative.css";
import BannerText from "../bannerText/bannerText";
import BannerCommon from "../bannerCommon/bannerCommon";

const BannerPresentative = () => {
  return (
    <>
      <div className="bannerPresentative">
        <BannerText />
        <BannerCommon />
      </div>
    </>
  );
};

export default BannerPresentative;
