import * as yup from "yup";

export const commonSchema = yup.object().shape({
  quoteTransportFrom: yup
    .string()
    .min(5, "The field must contain at least 5 characters")
    .required("Required"),
  quoteTransportTo: yup
    .string()
    .min(5, "The field must contain at least 5 characters")
    .required("Required"),
});
