import React from "react";
import "./header.css";
import Banner from "./banner/banner";

const MainHeader = () => {
  return (
    <div className="mainHeader">
      <Banner />
    </div>
  );
};

export default MainHeader;
