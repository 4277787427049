import React from "react";
import "./App.css";
import MainHeader from "../header/header";
import Main from "../main/main";
import Footer from "../footer/footer";
import Fixed from "../fixed/fixed";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "../header/menu/menu";
import { BrowserRouter } from "react-router-dom";
import { Container } from "react-bootstrap";

function App() {
  return (
    <>
      <Container className="p-0">
        <Menu />
        <MainHeader />
        <Main />
        <Footer />
      </Container>
    </>
  );
}

export default App;
