import React from "react";
import { useState } from "react";
import "./bannerCommon.css";
import { Tab, Tabs, Form, Button } from "react-bootstrap";
import BannerOrder from "../bannerOrder/bannerOrder";
import BannerQuote from "../bannerQuote/bannerQuote";
import { useFormik } from "formik";
import { commonSchema } from "./bannerCommonSchema";
import BannerCommonOrder from "../bannerCommonOrder/bannerCommonOrder";
import BannerCommonQuote from "../bannerCommonQuote/bannerCommonQuote";

const BannerCommon = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [showQuote, setShowQuote] = useState(false);

  const onQuoteSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
    console.log("Its working");
  };

  const onOrderSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
    console.log("Its working");
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues: {
      quoteTransportFrom: "",
      quoteTransportTo: "",
      orderTransportFrom: "",
      orderTransportTo: "",
    },
    validationSchema: commonSchema,
    onQuoteSubmit,
    onOrderSubmit,
  });

  return (
    <>
      <div className="bannerCommon">
        <Tabs>
          <Tab eventKey="quote" title="Quote">
            <BannerCommonQuote
              showQuote={showQuote}
              setShowQuote={setShowQuote}
            />
          </Tab>

          <Tab eventKey="order" title="Order">
            <BannerCommonOrder
              showOrder={showOrder}
              setShowOrder={setShowOrder}
            />
          </Tab>
        </Tabs>
      </div>

      <BannerQuote showQuote={showQuote} setShowQuote={setShowQuote} />
      <BannerOrder showOrder={showOrder} setShowOrder={setShowOrder} />
    </>
  );
};

export default BannerCommon;
