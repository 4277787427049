import React from "react";
import "./ourServices.css";
import MiniTypeBanner from "./miniTypeBanner/miniTypeBanner";
import MiniCityBanner from "./miniCityBanner/miniCityBanner";

function OurServices() {
  return (
    <div className="ourServices p-3">
      <h1>OUR SERVICES</h1>
      <h3
        className="pt-3 pb-3"
        style={{ alignSelf: "start", marginLeft: "6.5%" }}
      >
        Popular vehicle shipping services
      </h3>
      <MiniTypeBanner />
      <h3
        className="pt-3 pb-3"
        style={{
          alignSelf: "start",
          marginLeft: "6.5%",
        }}
      >
        Car transport anywhere in the U.S.
      </h3>
      <MiniCityBanner />
    </div>
  );
}

export default OurServices;
