import React from "react";
import "./bannerText.css";

const BannerText = () => {
  return (
    <div className="bannerText mt-5">
      <h1 id="evn"> EVN</h1>
      <h2 id="autoT">AUTO TRANSPORT</h2>
    </div>
  );
};

export default BannerText;
