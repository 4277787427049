import React from 'react';
import './describeSteps.css';
import { Container, Row, Card } from 'react-bootstrap';
import Step1 from '../../../../assets/describeStep/step1'
import Step2 from '../../../../assets/describeStep/step2'
import Step3 from '../../../../assets/describeStep/step3'



function DescribeStep() {


    return (
        <div className="describeStep" id='describeStep'>
            <Container>
                <Row className=' m-3 p-3 '>

                    <Card style={{ backgroundColor: "#ffffff", border: "none", borderRadius: '0', boxShadow: '0px 0px 14px 0px rgba(153,153,153,1)' }}>
                        <Card.Body>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='mx-3'>
                                    <Step1 />
                                </div>
                                <div></div>
                                <div>
                                    <Card.Title>Step 1</Card.Title>
                                </div>


                            </div>
                            <div className='pt-3'>
                                <Card.Text>
                                    Get a quote online or call now to talk to an auto transport expert.
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>

                </Row>
                <Row className=' m-3 p-3 '>
                    <Card style={{ backgroundColor: "#ffffff", border: "none", borderRadius: '0', boxShadow: '0px 0px 14px 0px rgba(153,153,153,1)' }}>
                        <Card.Body>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='mx-3'>
                                    <Step2 />
                                </div>
                                <div></div>
                                <div>
                                    <Card.Title>Step 2</Card.Title>
                                </div>


                            </div>
                            <div className='pt-3'>
                                <Card.Text>
                                    We carefully choose a reputable carrier that matches your needs.
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className=' m-3 p-3 '>
                    <Card style={{ backgroundColor: "#ffffff", border: "none", borderRadius: '0', boxShadow: '0px 0px 14px 0px rgba(153,153,153,1)' }}>
                        <Card.Body>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='mx-3'>
                                    <Step3 />
                                </div>
                                <div></div>
                                <div>
                                    <Card.Title>Step 3</Card.Title>
                                </div>


                            </div>
                            <div className='pt-3'>
                                <Card.Text>
                                    Your shipment is confirmed and your vehicle is picked up!
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div >

    );
}

export default DescribeStep;