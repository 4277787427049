import React from 'react';
import './howItWorks.css';
import Describe from './describe/describe';
import DescribeStep from './describeSteps/describeSteps';

function HowItWorks() {
    return (
        <div className="howItWorks">
            <h1 className='m-3 p-3'>HOW IT WORKS</h1>
            <Describe />
            <h1 className='m-3 p-3'>Reliable auto transport services in 3 easy steps</h1>
            <DescribeStep />
        </div>

    );
}

export default HowItWorks;