import React from 'react';
import './footer.css'
import FooterBanner from './footerBanner/footerBanner';

const Footer = () => {
    return (
        <div className="footer" id='footer'>
            <FooterBanner />
        </div >
    )
}

export default Footer;