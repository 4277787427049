import React from "react";
import "./gallary.css";
import { Carousel } from "react-bootstrap";
import photo1 from "../../../assets/gallary/photo1.jpg";
import photo2 from "../../../assets/gallary/photo2.jpg";
import photo3 from "../../../assets/gallary/photo3.jpg";
import photo4 from "../../../assets/gallary/photo4.jpg";
import photo5 from "../../../assets/gallary/photo5.jpg";
import photo6 from "../../../assets/gallary/photo6.jpg";
import photo7 from "../../../assets/gallary/photo7.jpg";
import photo8 from "../../../assets/gallary/photo8.jpg";

const Gallary = () => {
  return (
    <div className="gallary" id="gallary">
      <Carousel data-bs-theme="light" className=" m-3">
        <Carousel.Item>
          <img src={photo1} alt="First slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img src={photo2} alt="Second slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img src={photo4} alt="Third slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img src={photo6} alt="Third slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img src={photo7} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={photo3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={photo5} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={photo8} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Gallary;
