import React from "react";
import { useState } from "react";
import "./bannerCommonOrder.css";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { commonSchema } from "./bannerCommonOrderSchema";

const BannerCommonOrder = ({ showOrder, setShowOrder }) => {
  const onSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
    setShowOrder(true);
    console.log("Its working");
  };

  const {
    errors,
    values,
    touched,
    isValid,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      quoteTransportFrom: "",
      quoteTransportTo: "",
      orderTransportFrom: "",
      orderTransportTo: "",
    },
    validationSchema: commonSchema,
    onSubmit,
  });

  return (
    <div className="bannerCommonOrder">
      <Form className="m-3" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Transport car from</Form.Label>
          <Form.Control
            type="text"
            placeholder="ZIP or City"
            class="form-control"
            name="orderTransportFrom"
            id="orderTransportFrom"
            value={values.orderTransportFrom}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.orderTransportFrom && touched.orderTransportFrom
                ? "control-error"
                : ""
            }
          />
          {errors.orderTransportFrom && touched.orderTransportFrom && (
            <p className="">{errors.orderTransportFrom}</p>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Transport car to</Form.Label>
          <Form.Control
            type="text"
            placeholder="ZIP or City"
            class="form-control"
            name="orderTransportTo"
            id="orderTransportTo"
            value={values.orderTransportTo}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.orderTransportTo && touched.orderTransportTo
                ? "control-error"
                : ""
            }
          />
          {errors.orderTransportTo && touched.orderTransportTo && (
            <p className="">{errors.orderTransportTo}</p>
          )}
        </Form.Group>
        <Button
          variant="primary"
          style={{ marginLeft: "40%" }}
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default BannerCommonOrder;
