import React from "react";
import ReactDOM from "react-dom/client";
import App from "../src/components/app/App";
import "../src/index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Container } from "react-bootstrap";

// const router = createBrowserRouter([{
//   path: '/',
//   element: <App />
// }]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
