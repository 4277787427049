import React from "react";
import "./banner.css";
import BannerPresentative from "../bannerPresentative/bannerPresentative";
import { Image } from "react-bootstrap";
import bannerPhoto from "../../../assets/banner/bannerPhoto.jpeg";

const Banner = () => {
  return (
    <div className="banner mt-5">
      <Image src={bannerPhoto} />
      <BannerPresentative />
    </div>
  );
};

export default Banner;
