import React from "react";
import "./describe.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import Image1 from "../../../../assets/describe/image1";
import Image2 from "../../../../assets/describe/image2";
import Image3 from "../../../../assets/describe/image3";
import Image4 from "../../../../assets/describe/image4";
import Image5 from "../../../../assets/describe/image5";
import Image6 from "../../../../assets/describe/image6";
import Image7 from "../../../../assets/describe/image7";
import Image8 from "../../../../assets/describe/image8";

function Describe() {
  return (
    <div className="describe">
      <Container className="descContainer" id="describe">
        <Row className=" m-3 p-3 ">
          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image1 id={"svg"} />
              <Card.Body>
                <Card.Title>Leave it to the pros</Card.Title>
                <Card.Text>
                  As a top-rated auto transport company, you can have confidence
                  your vehicle will be transported safely. When shipping your
                  vehicle, you’re allowing our team of experts to handle every
                  step of the process, ensuring there are no speed bumps along
                  the way.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image2 id={"svg"} />
              <Card.Body>
                <Card.Title>Personalized approach</Card.Title>
                <Card.Text>
                  Why choose our auto transport company? EVN Auto Transport
                  works with you through every step of your car or truck’s move.
                  Experience hassle-free shipping when you choose us as your car
                  transporter and enjoy an easy and painless shipment process.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image3 id={"svg"} />
              <Card.Body>
                <Card.Title>Dedicated advisors</Card.Title>
                <Card.Text>
                  Our professional advisors work around the clock to ensure you
                  receive the best auto transport experience.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image4 id={"svg"} />
              <Card.Body>
                <Card.Title>The best company</Card.Title>
                <Card.Text>
                  Why do customers say EVN is the best company to ship a car?
                  Check out our online reviews to see why we’re widely
                  considered one of the best car moving companies and car
                  hauling companies in the business.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className=" m-3 p-3 ">
          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image5 id={"svg"} />
              <Card.Body>
                <Card.Title>Zero upfront payment</Card.Title>
                <Card.Text>
                  You read that right, no upfront payment! The best auto
                  transport companies charge you once the carrier has been
                  dispatched for your car shipping order. So, you can book with
                  your auto transport company now and not worry about the bill
                  until later.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image6 id={"svg"} />
              <Card.Body>
                <Card.Title>Insurance coverage</Card.Title>
                <Card.Text>
                  Car transport companies include insurance coverage in your
                  shipping quote. Our selected auto haulers must meet insurance
                  standards before qualifying to be part of our carrier network.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image7 id={"svg"} />
              <Card.Body>
                <Card.Title>15K+ auto carriers</Card.Title>
                <Card.Text>
                  Our auto transporter company has a huge network of haulers who
                  are personally vetted auto shipping experts, ensuring your
                  vehicle is transported by trusted members of the industry.
                  These vehicle transport companies will treat your car like
                  their own.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: "15rem", border: "none" }}>
              <Image8 id={"svg"} />
              <Card.Body>
                <Card.Title>Extended hours</Card.Title>
                <Card.Text>
                  Our award-winning auto transport advisors work 365 days a year
                  to guarantee we’re available to address your vehicle
                  transportation needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Describe;
