import React from "react";
import { useState } from "react";
import "./bannerCommonQuote.css";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { commonSchema } from "./bannerCommonQuoteSchema";

const BannerCommonQuote = ({ showQuote, setShowQuote }) => {
  const onSubmit = async (values, actions) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
    setShowQuote(true);
    console.log("Its working");
  };

  const {
    errors,
    values,
    touched,
    isValid,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      quoteTransportFrom: "",
      quoteTransportTo: "",
    },
    validationSchema: commonSchema,
    onSubmit,
  });

  return (
    <div className="bannerCommonQuote">
      <Form className="m-3" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Transport car from</Form.Label>
          <Form.Control
            type="text"
            placeholder="ZIP or City"
            class="form-control"
            name="quoteTransportFrom"
            id="quoteTransportFrom"
            value={values.quoteTransportFrom}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.quoteTransportFrom && touched.quoteTransportFrom
                ? "control-error"
                : ""
            }
          />
          {errors.quoteTransportFrom && touched.quoteTransportFrom && (
            <p className="">{errors.quoteTransportFrom}</p>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Transport car to</Form.Label>
          <Form.Control
            type="text"
            placeholder="ZIP or City"
            class="form-control"
            name="quoteTransportTo"
            id="quoteTransportTo"
            value={values.quoteTransportTo}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.quoteTransportTo && touched.quoteTransportTo
                ? "control-error"
                : ""
            }
          />
          {errors.quoteTransportTo && touched.quoteTransportTo && (
            <p className="">{errors.quoteTransportTo}</p>
          )}
        </Form.Group>
        <Button
          variant="primary"
          style={{ marginLeft: "40%" }}
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default BannerCommonQuote;
